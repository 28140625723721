import React from 'react'

import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'
import dayjs from 'dayjs'
import { ToWords } from 'to-words'
import pdfMake from 'pdfmake/build/pdfmake'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { dbTodayjs } from '../../../utils/tools'

import pdfFonts from '../../../assets/pdfMakeFonts/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false
  }
})

const formatComma = (value) => {
  if (!value || isNaN(value)) {
    return ''
  }
  return new Intl.NumberFormat('en-US').format(value)
}

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

const BookingConfirmedPdf = ({ record, bottomType }) => {
  const { db, role, companyMaster, companySelected } = useAuth()

  const header = (poData) => {
    const data = [
      {
        columns: [
          [
            {
              text: poData.company?.name ?? '',
              fontSize: 18,
              bold: true
            },
            {
              text: poData.company?.address ?? '',
              bold: true
            },
            {
              text: `EMAIL : ${poData.company?.email ?? ''}`,
              bold: true
            }
          ],
          {
            alignment: 'right',
            image: poData.imageData,
            width: 80
          }
        ]
      }
    ]
    return data
  }

  const soldOrderPacking = (obj) => {
    const marks = obj.products.map(e => e.marks)
    const strMarks = marks.join('\n')
    const data = [
      {
        columns: [
          {
            width: '*',
            stack: [
              {
                text: 'SOLD ORDER AND FOR THE ACCOUNT AND RISK OF:'
              },
              {
                table: {
                  widths: [40, '*'],
                  heights: ['*', 50],
                  body: [
                    [{ text: 'SOLD TO:', rowSpan: 2 }, { text: _.get(obj, 'poForm.soldTo', ''), rowSpan: 2 }],
                    ['', '']
                  ]
                }
              },
              {
                text: `SHIP VIA: ${_.get(obj, 'poForm.vesselName', '')} SEA FREGHT  ${_.get(obj, 'poForm.volume', '')}`
              },
              {
                text: `MOTHER VESSEL NAME: ${_.get(obj, 'poForm.motherVesselName', '')}`
              },
              {
                text: `CONTAINER/SEAL NO.: ${obj?.poForm?.containerNo ?? ''}/${obj?.poForm?.sealsNo ?? ''}`
              },
              {
                text: `HBL NO: ${_.get(obj, 'poForm.hbl', '')}`
              }
            ]
          },
          {
            width: 16,
            text: ' '
          },
          {
            width: '*',
            stack: [
              {
                table: {
                  widths: [40, '*', 70],
                  body: [
                    [{ text: 'DATE:' }, { text: obj.packingDate, colSpan: 2 }, {}],
                    [{ text: 'NO.:' }, { text: _.get(obj, 'poForm.invNo', ''), colSpan: 2 }, {}],
                    [{ text: `DELIVERY FROM: ${(obj?.poForm?.portOfLoading ?? '').toUpperCase()}`, colSpan: 3 }, {}, {}],
                    [{ text: `FINAL DESTINATION: ${(obj?.poForm?.finalDestination ?? '').toUpperCase()}`, colSpan: 3 }, {}, {}],
                    [{ text: `PORT OF DISCHARGE: ${_.get(obj, 'poForm.portOfDischarge', '')}`, colSpan: 3 }, {}, {}],
                    [{ text: `TRANSHIPMENT PORT:  ${_.get(obj, 'poForm.transhipmentPort', '')}`, colSpan: 3 }, {}, {}],
                    [{ text: 'MARKS:', rowSpan: 2 }, { text: strMarks, rowSpan: 2 }, { text: 'Customer PO NO:' }],
                    [{}, {}, { text: _.get(obj, 'poForm.poNo', '') }],
                  ]
                }
              },
              {
                text: `ETD: ${_.get(obj, 'poForm.etd') ? dayjs(_.get(obj, 'poForm.etd')).format('DD-MMM-YY') : ''}`
              },
              {
                text: `ETA: ${_.get(obj, 'poForm.eta') ? dayjs(_.get(obj, 'poForm.eta')).format('DD-MMM-YY') : ''}`
              }
            ]
          }
        ]
      }
    ]
    return data
  }

  const soldOrderInvoice = (obj) => {
    const marks = obj.products.map(e => e.marks)
    const strMarks = marks.join('\n')
    const data = [
      {
        columns: [
          {
            width: '*',
            stack: [
              {
                text: 'SOLD ORDER AND FOR THE ACCOUNT AND RISK OF:'
              },
              {
                table: {
                  heights: ['*', 50],
                  widths: [40, '*'],
                  body: [
                    [{ text: 'SOLD TO:', rowSpan: 2 }, { text: _.get(obj, 'poForm.soldTo', ''), rowSpan: 2 }],
                    ['', '']
                  ]
                }
              },
              {
                text: `SHIP VIA: ${_.get(obj, 'poForm.vesselName', '')} SEA FREGHT  ${_.get(obj, 'poForm.volume', '')}`
              },
              {
                text: `CONTAINER/SEAL NO.: ${obj?.poForm?.containerNo ?? ''}/${obj?.poForm?.sealsNo ?? ''}`
              },
              {
                text: `B/L NO: ${_.get(obj, 'poForm.hbl', '')}`
              }
            ]
          },
          {
            width: 16,
            text: ''
          },
          {
            width: '*',
            stack: [
              {
                table: {
                  widths: [40, '*', 70],
                  body: [
                    [{ text: 'DATE:' }, { text: obj.invoiceDate, colSpan: 2 }, {}],
                    [{ text: 'NO.:' }, { text: _.get(obj, 'poForm.invNo', ''), colSpan: 2 }, {}],
                    [{ text: `DELIVERY FROM: ${(obj?.poForm?.portOfLoading ?? '').toUpperCase()}`, colSpan: 3 }, {}, {}],
                    [{ text: `FINAL DESTINATION: ${(obj?.poForm?.finalDestination ?? '').toUpperCase()}`, colSpan: 3 }, {}, {}],
                    [{ text: `PORT OF DISCHARGE: ${_.get(obj, 'poForm.portOfDischarge', '')}`, colSpan: 3 }, {}, {}],
                    [{ text: 'MARKS:', rowSpan: 2 }, { text: strMarks, rowSpan: 2 }, { text: 'Customer PO NO:' }],
                    [{}, {}, { text: _.get(obj, 'poForm.poNo', '') }],
                  ]
                }
              },
              {
                text: `ETD: ${_.get(obj, 'poForm.etd') ? dayjs(_.get(obj, 'poForm.etd')).format('DD-MMM-YY') : ''}`
              },
              {
                text: `ETA: ${_.get(obj, 'poForm.eta') ? dayjs(_.get(obj, 'poForm.eta')).format('DD-MMM-YY') : ''}`
              }
            ]
          }
        ]
      }
    ]
    return data
  }

  const tableDescriptionPacking = (data) => {
    const result = [
      {
        stack: [
          {
            table: {
              headerRows: 1,
              widths: [20, '*', 70, 80, 85],
              body: [
                [
                  {
                    text: 'No.',
                    alignment: 'center'
                  },
                  {
                    text: 'DESCRIPTION OF GOODS',
                    alignment: 'center'
                  },
                  {
                    text: 'QUANTITY (CTN)',
                    alignment: 'center'
                  },
                  {
                    text: 'NET WEIGHT (KGS)',
                    alignment: 'center'
                  },
                  {
                    text: 'GROSS WEIGHT (KGS)',
                    alignment: 'center'
                  }
                ],
                ...data.showProductsPack.showProducts
              ],
            }
          },
          {
            columns: [
              {
                text: ''
              },
              [
                {
                  alignment: 'right',
                  text: `NET WEIGHT: ${data.showProductsPack.totalNewWeight}`
                },
                {
                  alignment: 'right',
                  text: `GROSS WEIGHT: ${data.showProductsPack.totalGrossWeight}`
                },
                {
                  alignment: 'right',
                  text: `MESUREMENT: ${data.showProductsPack.measurement}`
                }
              ]
            ]
          }
        ]

      }
    ]
    return result
  }

  const tableDescriptionInvoice = (obj) => {
    const words = toWords.convert(obj.totalBalance)
    const data = [
      {
        stack: [
          {
            table: {
              headerRows: 1,
              widths: [20, '*', 70, 90, 75],
              body: [
                [
                  {
                    text: 'No.',
                    alignment: 'center'
                  },
                  {
                    text: 'DESCRIPTION OF GOODS',
                    alignment: 'center'
                  },
                  {
                    text: 'QUANTITY (CTN)',
                    alignment: 'center'
                  },
                  {
                    text: 'UNIT PRICE (USD)',
                    alignment: 'center'
                  },
                  {
                    text: 'TOTAL PRICE (USD)',
                    alignment: 'center'
                  }
                ],
                ...obj.showProductInvoice.showProducts
              ],
            }
          },
          '\n',
          {
            alignment: 'center',
            text: words.toUpperCase()
          },
          '\n', '\n'
        ]
      }
    ]
    return data
  }

  const footerPackingList = (poData) => {
    const data = [
      {
        margin: [0, 40, 0, 0],
        columns: [
          {
            width: '*',
            text: ''
          },
          {
            width: '*',
            text: ''
          },
          {
            alignment: 'center',
            width: '*',
            stack: [
              {
                text: '.........................................'
              },
              {
                text: `(${poData?.poForm?.approvalName ?? 'APHINYA KUNAETR'})`
              },
              {
                text: poData?.poForm?.approvalPosition ?? 'SALES AND LOGISTICS'
              }
            ]
          }
        ]
      }
    ]
    return data
  }

  const footerInvoice = (poData) => {
    const wLabel = 80
    const fontSize = 10
    const data = [
      {
        text: 'Banking Information:'
      },
      {
        stack: [
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Bank:'
              },
              {
                fontSize,
                text: poData.company?.bankname ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Branch:'
              },
              {
                fontSize,
                text: poData.company?.bankbranch ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Address:'
              },
              {
                fontSize,
                text: poData.company?.bankaddress ?? ''
              }
            ]
          },
          {

            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Account Name: '
              },
              {
                fontSize,
                text: poData.company?.bankaccount ?? ''
              }
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Account Number:'
              },
              {
                fontSize,
                text: poData.company?.banknumber ?? ''
              },
            ]
          },
          {
            columns: [
              {
                width: wLabel,
                fontSize,
                text: 'Swift No.:'
              },
              {
                fontSize,
                text: poData.company?.bankswift ?? ''
              }
            ]
          }
        ]
      },
      '\n', '\n', '\n',
      {
        columns: [
          {
            width: '*',
            text: ' '
          },
          {
            width: '*',
            text: ' '
          },
          {
            alignment: 'center',
            width: '*',
            stack: [
              {
                text: '.........................................'
              },
              {
                text: `(${poData?.poForm?.approvalName ?? 'APHINYA KUNAETR'})`
              },
              {
                text: poData?.poForm?.approvalPosition ?? 'SALES AND LOGISTICS'
              }
            ]
          }
        ]
      }
    ]
    return data
  }

  const exportPackingList = (data) => {
    const result = {
      content: [
        ...header(data),
        { text: 'PACKING LIST & WEIGHT NOTE', fontSize: 18, bold: true, alignment: 'center', },
        '\n',
        ...soldOrderPacking(data),
        '\n',
        ...tableDescriptionPacking(data),
        ...footerPackingList(data)
      ],
      defaultStyle: {
        fontSize: 12,
        font: 'THSarabunNew',
        bold: true
      }
    }
    return result
  }

  const exportInvoice = (data) => {
    const result = {
      content: [
        ...header(data),
        { text: 'INVOICE', fontSize: 18, bold: true, alignment: 'center', },
        '\n',
        ...soldOrderInvoice(data),
        '\n',
        ...tableDescriptionInvoice(data),
        ...footerInvoice(data)
      ],
      defaultStyle: {
        fontSize: 12,
        font: 'THSarabunNew',
        bold: true
      }
    }
    return result
  }

  const formatShowProductPack = (products) => {
    let totalConfirmCtn = 0
    let totalNewWeight = 0
    let totalGrossWeight = 0
    let totalMeasurement = 0
    let showProducts = products.map((e, i) => {
      const netWeight = (e.weight * 0.001) * e.bagPerCarton * e.confirmCTN
      const grossWeight = e.weightPerCTN * e.confirmCTN
      totalConfirmCtn += e.confirmCTN
      totalNewWeight += netWeight
      totalGrossWeight += grossWeight
      totalMeasurement += (e.confirmCTN * e.packagingWidth * e.packagingHeight * e.packagingLength / 1000000)
      return [
        {
          border: [true, false, true, false],
          alignment: 'center',
          text: i + 1
        },
        {
          border: [true, false, true, false],
          text: `${e.productNameEN}\n${e.weight}G. X ${e.bagPerCarton} PER CTN\n\nPRODUCTION DATE: ${(e.prodDate) ? dayjs(dbTodayjs(e.prodDate)).format('DD/MM/YYYY') : 'TBC'}\nBEST BEFORE DATE: ${(e.bbd) ? dayjs(dbTodayjs(e.bbd)).format('DD/MM/YYYY') : 'TBC'}\nBATCH NO. ${e?.batchNo ?? 'TBC'}`
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma(e.confirmCTN)
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma(netWeight.toFixed(2))
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma(grossWeight.toFixed(2))
        }
      ]
    })
    showProducts = [...showProducts, [{ text: '' }, { text: 'TOTAL', alignment: 'center' }, { text: formatComma(totalConfirmCtn), alignment: 'right' }, { text: formatComma(totalNewWeight.toFixed(2)), alignment: 'right' }, { text: formatComma(totalGrossWeight.toFixed(2)), alignment: 'right' }]]
    return {
      showProducts,
      totalNewWeight: formatComma(totalNewWeight.toFixed(2)) + ' KG.',
      totalGrossWeight: formatComma(totalGrossWeight.toFixed(2)) + ' KG.',
      measurement: formatComma(totalMeasurement.toFixed(3)) + ' M3'
    }
  }

  const formatShowProductInvoice = (data) => {
    let totalConfirmCtn = 0
    let totalBalance = 0
    const fob = (!data?.portOfLoading) ? '' : (data.portOfLoading.indexOf('Bangkok') !== -1) ? 'F.O.B BANGKOK' : 'F.O.B. LAEM CHABANG'
    let showProducts = data.products.map((e, i) => {
      const totalPrice = (e.price * e.bagPerCarton) * e.confirmCTN
      totalConfirmCtn += e.confirmCTN
      totalBalance += totalPrice
      return [
        {
          border: [true, false, true, false],
          alignment: 'center',
          text: i + 1
        },
        {
          border: [true, false, true, false],
          text: `${e.productNameEN}\n${e.weight}G. X ${e.bagPerCarton} PER CTN`
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma(e.confirmCTN)
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma((e.price * e.bagPerCarton).toFixed(2))
        },
        {
          border: [true, false, true, false],
          alignment: 'right',
          text: formatComma(totalPrice.toFixed(2))
        }
      ]
    })
    showProducts = [
      ...showProducts,
      [
        {
          border: [true, true, true, false],
          text: ''
        },
        {
          border: [true, true, true, false],
          text: ''
        },
        {
          border: [true, true, true, false],
          text: ''
        },
        {
          border: [true, true, true, false],
          text: fob,
          alignment: 'center'
        },
        {
          border: [true, true, true, false],
          alignment: 'right',
          text: formatComma(totalBalance.toFixed(2))
        }
      ],
      [
        {
          border: [true, false, true, true],
          text: ''
        },
        {
          border: [true, false, true, true],
          text: 'TOTAL',
          alignment: 'right'
        },
        {
          border: [true, false, true, true],
          text: formatComma(totalConfirmCtn) + ' CARTON',
          alignment: 'right'
        },
        {
          border: [true, false, true, true],
          text: 'TOTAL BALANCE',
          alignment: 'center'
        },
        {
          border: [true, false, true, true],
          text: formatComma(totalBalance.toFixed(2)),
          alignment: 'right'
        }
      ]
    ]
    return {
      totalBalance,
      showProducts
    }
  }

  const genPdf = async () => {
    const isFoodFarm = ['croft foods', 'croftfoods'].find((o) => (companySelected?.name ?? '').toLowerCase().indexOf(o) !== -1)
    const company = (isFoodFarm) ? companyMaster : companySelected

    const imageData = await toDataURL(company.url)

    record.etd = dbTodayjs(record.etd)
    record.eta = dbTodayjs(record.eta)
    const showProductsPack = formatShowProductPack(record.products)
    const showProductInvoice = formatShowProductInvoice(record)
    const data = {
      poForm: record,
      products: record.products,
      showProductsPack,
      showProductInvoice,
      totalBalance: showProductInvoice.totalBalance,
      invoiceDate: (record?.invoiceDate) ? dayjs(dbTodayjs(record.invoiceDate)).format('DD-MMM-YY') : dayjs().format('DD-MMM-YY'),
      packingDate: (record?.packingDate) ? dayjs(dbTodayjs(record.packingDate)).format('DD-MMM-YY') : dayjs().format('DD-MMM-YY'),
      imageData,
      company
    }
    const consigneeId = _.get(data, 'poForm.consigneeId')
    if (consigneeId) {
      const res = await db.collection('consignee').doc(consigneeId).get()
      data.poForm.soldTo = `${res.data().consigName}\n${res.data().consigAddress}`
    }
    if (role !== 'Customer') {
      if (!(record?.invoiceDate && record?.packingDate)) {
        await db.collection('po').doc(record.id).set({
          invoiceDate: (record?.invoiceDate) ? record.invoiceDate : new Date(),
          packingDate: (record?.packingDate) ? record.packingDate : new Date()
        }, {
          merge: true
        })
      }
    }

    const invoiceObj = exportInvoice(data)
    const packingObj = exportPackingList(data)
    pdfMake.createPdf(invoiceObj).download(`Invoice_${record.invNo}`)
    pdfMake.createPdf(packingObj).download(`Packing List & Weight Note_${record.invNo}`)
  }

  return (
    <>
      {(bottomType === 'text') ? (
        <Button
          style={{ margin: '20px 10px 0', width: '300px' }}
          type="secondary"
          size="large"
          onClick={() => genPdf()}
        >Download</Button>
      ) : (
        <Button
          type="primary"
          size="middle"
          icon={<FontAwesomeIcon icon={faFileExport} className="fa-icon-button" />}
          onClick={() => genPdf()}
        ></Button>
      )}
    </>
  )
}

export default BookingConfirmedPdf
